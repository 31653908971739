export default [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "revealedURI",
        "type": "string"
      }
    ],
    "name": "TokenURIRevealed",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      },
      {
        "internalType": "bytes",
        "name": "key",
        "type": "bytes"
      }
    ],
    "name": "encryptDecrypt",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "result",
        "type": "bytes"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "identifier",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "key",
        "type": "bytes"
      }
    ],
    "name": "reveal",
    "outputs": [
      {
        "internalType": "string",
        "name": "revealedURI",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];